import { Component, HostListener } from '@angular/core'

@Component({
  selector: 'app-layout-portal',
  templateUrl: './layout.portal.component.html',
  styleUrls: ['./layout.portal.style.scss'],
})
export class LayoutPortalComponent {
  constructor() {}

  // ngOnInit() {}
}
