import { Routes } from '@angular/router'
import { LayoutMainComponent } from './layout/main/layout.main.component'
import { LayoutPortalComponent } from './layout/portal/layout.portal.component'

const routes: Routes = []

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutPortalComponent,
    children: [{ path: '', loadChildren: () => import('./modules/portal.module').then(m => m.PortalModule) }],
  },
  {
    path: '',
    component: LayoutMainComponent,
    children: [{ path: '', loadChildren: () => import('./modules/main.module').then(m => m.MainModule) }],
  },
]
