<button disabled class="loader-btn" [@scrollAnimation]="state" md-raised-button>
  <div class="lds-css ng-scope">
    <div style="width:100%;height:100%" class="lds-wedges">
      <div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  Processing
</button>
