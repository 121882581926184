import { Directive, EventEmitter, HostListener, Output } from '@angular/core'

@Directive({
  selector: '[swift]',
})
export class SwiftTouchDirective {
  @Output() touch = new EventEmitter()
  public isDevice: boolean
  public isChrome: boolean
  constructor() {
    const ua = navigator.userAgent
    if (/Chrome/i.test(ua)) {
      this.isChrome = true
    }
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      this.isDevice = true
    } else {
      this.isDevice = false
    }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(e) {
    event.preventDefault()
    if (!this.isDevice || this.isChrome) {
      this.touch.emit(e)
    }
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(e) {
    event.preventDefault()
    if (this.isDevice || this.isChrome) {
      this.touch.emit(e)
    }
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(e) {
    event.preventDefault()
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(e) {
    event.preventDefault()
  }
}
