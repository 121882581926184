import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { NoopAnimationsModule } from '@angular/platform-browser/animations'
import { PreloadAllModules, RouterModule } from '@angular/router'
import { AppComponent } from './app.component'
import { AppConfig } from './app.config'
import { AppRoutes } from './app.routes'
import { FooterComponent } from './layout/footer/footer.component'
import { HeaderComponent } from './layout/header/header.component'
import { LayoutMainComponent } from './layout/main/layout.main.component'
import { LayoutPortalComponent } from './layout/portal/layout.portal.component'
import { HttpAuth } from './middleware/http/http.auth'
import { GlobalModule } from './modules/global.module'

@NgModule({
  declarations: [AppComponent, LayoutPortalComponent, LayoutMainComponent, HeaderComponent, FooterComponent],
  imports: [HttpClientModule, BrowserModule, NoopAnimationsModule,
    GlobalModule, RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules })],
  providers: [HttpAuth, AppConfig],
  bootstrap: [AppComponent],
})
export class AppModule { }
