import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

export interface IRequestOptions {
  headers?: HttpHeaders
  observe?: 'body'
  params?: HttpParams
  reportProgress?: boolean
  responseType?: 'json'
  withCredentials?: boolean
  body?: any
}

@Injectable()
export class HttpAuth {
  private api = `${environment.apiURL}`

  // Extending the HttpClient through the Angular DI.
  public constructor(public http: HttpClient) {
    // If you don't want to use the extended versions in some cases you can access the public property and use the original one.
    // for ex. this.httpClient.http.get(...)
  }

  /**
   * GET request
   * @param string endPoint it doesn't need / in front of the end point
   * @param IRequestOptions options options of the request like headers, body, etc.
   * @returns Observable<T>
   */
  public get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.http.get<T>(this.updateEndpoint(endPoint), options)
  }

  /**
   * POST request
   * @param string endPoint end point of the api
   * @param Object params body of the request.
   * @param IRequestOptions options options of the request like headers, body, etc.
   * @returns Observable<T>
   */
  public post<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
    return this.http.post<T>(this.updateEndpoint(endPoint), params, options)
  }

  /**
   * PUT request
   * @param string endPoint end point of the api
   * @param Object params body of the request.
   * @param IRequestOptions options options of the request like headers, body, etc.
   * @returns Observable<T>
   */
  public put<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
    return this.http.put<T>(this.updateEndpoint(endPoint), params, options)
  }

  /**
   * DELETE request
   * @param string endPoint end point of the api
   * @param IRequestOptions options options of the request like headers, body, etc.
   * @returns Observable<T>
   */
  public delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.http.delete<T>(this.updateEndpoint(endPoint), options)
  }

  private updateEndpoint(endpoint: string) {
    return `${this.api}${endpoint}`
  }
}

export function HttpAuthCreator(http: HttpClient) {
  return new HttpAuth(http)
}
