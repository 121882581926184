import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { LoaderComponent } from '../components/loader/loader.component'
import { SwiftTouchDirective } from '../directives/swift.touch'
import { MaterialModule } from './material.module'

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  declarations: [LoaderComponent],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, LoaderComponent],
  providers: [SwiftTouchDirective],
  entryComponents: [],
})
export class GlobalModule { }
