import { Injectable } from '@angular/core'
declare var require: any

@Injectable()
export class AppConfig {
  public SETTINGS = {
    DEBUG_MODE: false,
    UPDATES: false,
    DEBUG_LOGIN: false,
    APP_VERSION: require('../../package.json').version,
  }

  public API_BASE = ''
  public FIREBASE_ENV = ''

  constructor() { }
}
