import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-layout-main',
  templateUrl: './layout.main.component.html',
  styleUrls: ['./layout.main.style.scss'],
})
export class LayoutMainComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
